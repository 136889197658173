import React, { useContext, useEffect } from 'react';
import FunnelContext from '../../../context/FunnelContext';
import { breeds, months, years, breeds2 } from './OptionValues';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import useLocalStorage from '../../../hooks/useLocalStorage';

const AnswerSelect2 = props => {
  const { answers, setAnswers } = useContext(FunnelContext);
  const [answersLS, setAnswersLS] = useLocalStorage('answers', {});
  const {
    type,
    options,
    range_start = 0,
    range_end = 10,
    isFullWidth,
    name,
    placeholder,
    showMenuOnFocus,
    filterByPetType = 'dog'
  } = props;

  useEffect(() => {
    const newAnswerData = Object.assign(answers, answersLS);
    setAnswers(newAnswerData);
    setAnswersLS(newAnswerData);
  }, [answers, setAnswers, answersLS, setAnswersLS]);

  const handleChange = event => {
    if (event) {
      const newAnswers = Object.assign(answersLS, {
        [name]: event.value,
        [`${name}-label`]: event?.label
      });
      setAnswersLS(newAnswers);
    } else {
      const newAnswersLS = Object.assign(answersLS, {
        [name]: '',
        [`${name}-label`]: ''
      });
      setAnswersLS(newAnswersLS);
    }
  };

  const canCreate = () => {
    switch (type) {
      case 'breeds':
        return true;
      default:
        return false;
    }
  };

  const OptionsData = () => {
    switch (type) {
      case 'breeds':
        return breeds;
      case 'breeds2':
        return breeds2.filter(b => b.petType === filterByPetType);
      case 'months':
        return months;
      case 'years':
        return years;
      case 'range':
        const ranges = [];
        for (let i = range_start; i <= range_end; i++) {
          ranges.push({ value: i, label: i });
        }
        return ranges;
      default:
      case 'normal':
        return options;
    }
  };

  const Container = () => {
    if (canCreate()) {
      return (
        <CreatableSelect
          openMenuOnFocus={!!showMenuOnFocus}
          openMenuOnClick={!!showMenuOnFocus}
          styles={{
            control: base => ({
              ...base,
              width: isFullWidth ? '100%' : 'initial'
            })
          }}
          placeholder={placeholder || 'Select...'}
          isClearable={true}
          isSearchable={true}
          options={OptionsData()}
          {...props.attributes}
          onChange={handleChange}
          defaultValue={
            answersLS[name]
              ? {
                  value: answersLS[name],
                  label: answersLS[`${name}-label`]
                }
              : null
          }
        />
      );
    } else {
      return (
        <Select
          openMenuOnFocus={!!showMenuOnFocus}
          openMenuOnClick={!!showMenuOnFocus}
          placeholder={placeholder || 'Select...'}
          isClearable={true}
          isSearchable={true}
          options={OptionsData()}
          {...props.attributes}
          onChange={handleChange}
          defaultValue={
            answersLS[name]
              ? {
                  value: answersLS[name],
                  label: answersLS[`${name}-label`]
                }
              : null
          }
          styles={{
            control: base => ({
              ...base,
              width: isFullWidth ? '100%' : 'initial'
            })
          }}
        />
      );
    }
  };

  return <Container />;
};

export default AnswerSelect2;
